import strings from "@constants/strings";
import { colors, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";

interface DataType {
  content: string;
  key: number;
}

interface PrecautionsProps {
  title?: string;
  data?: DataType[];
  background?: string;
  heightExclude?: number;
}

/**
 * 
 * @param title string
 * @param data DataType {
  content: string;
  key: number;
}[]
 * @param background  string
 * @param heightExclude  number || header, contents, bottomWrap 의 높이값을 더해서 넣어주면 그 높이 값을 제외해 뷰의 높이를 체운다.
 */
const Precautions: React.FC<PrecautionsProps> = ({
  title,
  data,
  background,
  heightExclude,
}) => {
  return (
    <>
      <PrecautionsWrap background={background} heightExclude={heightExclude}>
        <PrecautionsTitle>
          {title || strings.SubTitle.Precautions}
        </PrecautionsTitle>

        <PrecautionsContentWrap>
          <PrecautionsTextWrap>
            {data?.map(({ key, content }) => {
              return <PrecautionsText key={key}>{content}</PrecautionsText>;
            })}
          </PrecautionsTextWrap>
        </PrecautionsContentWrap>
      </PrecautionsWrap>
    </>
  );
};

export default Precautions;

const PrecautionsWrap = styled.div<{
  background?: string;
  heightExclude?: number;
}>`
  /*  */
  width: 100%;
  min-height: 270px;
  height: ${({ heightExclude }) =>
    heightExclude ? `calc(100vh - ${heightExclude}px)` : ""};
  background: ${({ background }) =>
    background ? background : `${colors.GRAY10}`};
  padding: 24px 20px 0px;
`;

const PrecautionsContentWrap = styled.div`
  /*  */
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 12px;
`;

const PrecautionsTextWrap = styled.div``;

const PrecautionsTitle = styled.p`
  /*  */
  ${typo.HEADING_8}
  color: ${colors.GRAY4};
`;

export const PrecautionsText = styled.p`
  /*  */
  ${typo.BODY_9}
  padding-left: 20px;
  position: relative;
  color: ${colors.GRAY6};

  &::before {
    content: "";
    position: absolute;
    left: 8px;
    top: 9px;
    width: 3px;
    height: 3px;
    border-radius: 100%;
    display: inline-block;
    background-color: ${colors.GRAY5}; //XXX 색상 ?
  }

  & + & {
    margin-top: 4px;
  }
`;
