import Button from "@components/atoms/Button";
import strings from "@constants/strings";
import styled from "styled-components";
import { ModalTitle } from "../AlertModal";
import BaseModal from "../BaseModal";

export interface TwoBtnModalProps {
  title: string;
  firstButtonText?: string;
  secondButtonText?: string;
  onClickFirstButton: () => void;
  onClickSecondButton: () => void;
  handleClose?: () => void;
}

function TwoBtnModal({
  title,
  firstButtonText = strings.Modal.Button.No,
  secondButtonText = strings.Modal.Button.Yes,
  onClickFirstButton,
  onClickSecondButton,
  handleClose,
}: TwoBtnModalProps) {
  return (
    <BaseModal isOpen handleClose={handleClose}>
      <ModalTitle>{title}</ModalTitle>
      <ButtonWrap>
        <Button
          full
          color="gray"
          text={firstButtonText}
          style={{ padding: "16px 12px" }}
          onClick={onClickFirstButton}
        />
        <Button
          full
          text={secondButtonText}
          style={{ padding: "16px 12px" }}
          onClick={onClickSecondButton}
        />
      </ButtonWrap>
    </BaseModal>
  );
}

export default TwoBtnModal;

const ButtonWrap = styled.div`
  /*  */
  display: flex;
  justify-content: space-between;
  gap: 7px; ;
`;
