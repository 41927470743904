import request from "@network/index";
import {
  CheckChargerResponse,
  PaymentsTermsResponse,
  PaymentResponse,
  PaymentRequest,
  Row,
  Rows,
  ChargerStateResponse,
  ChargerStopRequest,
} from "./types/index";

/**
 * 충전기 조회
 */
export const requestCheckCharger = async (params: string) => {
  const response = await request<string, Row<CheckChargerResponse>>({
    method: "get",
    url: `/customer/chargers/${params}`,
  });

  return response.data.row;
};

/**
 * 약관 조회
 */
export const requestPaymentsTerms = async () => {
  const response = await request<
    { channel: string },
    Rows<PaymentsTermsResponse>
  >({
    method: "get",
    url: `/customer/common/terms`,
    requestParams: {
      channel: "guestPayment",
    },
  });

  return response.data.rows;
};

/**
 * 비회원 결제
 */
export const requestGuestsPayment = async (body: PaymentRequest) => {
  const response = await request<PaymentRequest, Row<PaymentResponse>>({
    method: "post",
    url: `/guests/payment`,
    requestBody: body,
  });

  console.log(">>>🔱 PAY RESPONSE DATA  : ", response);
  return response.data.row;
};

/**
 * 비회원 충전상태 조회
 */
export const requestChargerState = async (params?: string) => {
  const response = await request<string, Row<ChargerStateResponse>>({
    method: "get",
    url: `/guests/chargers/charge-status/${params}`,
  });

  console.log(">>>🔱 PAY RESPONSE DATA  : ", response);
  return response.data.row;
};

/**
 * 비회원 원격 충전 종료
 */
export const requestChargerStop = async (body: ChargerStopRequest) => {
  await request<ChargerStopRequest, void>({
    method: "put",
    url: `/guests/chargers/stop`,
    requestBody: body,
  });
};
