import Button from "@components/atoms/Button";
import { colors, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";
import BaseModal from "../BaseModal";

/** TODO
 * modal 오픈시 버튼에 focus 가게?
 */

export interface AlertModalProps {
  title?: string;
  message?: string;
  buttonText?: string;
  handleClose?: () => void;
  handleConfirm?: any;
}

function AlertModal({
  title = "title",
  message,
  buttonText = "확인 ",
  handleClose,
  handleConfirm,
}: AlertModalProps) {
  return (
    <BaseModal isOpen handleClose={handleClose}>
      <TextWrap>
        <ModalTitle>{title}</ModalTitle>
        {message && <ModalMessage>{message}</ModalMessage>}
      </TextWrap>
      <Button text={buttonText} onClick={handleConfirm} />
    </BaseModal>
  );
}

export default AlertModal;

const TextWrap = styled.div`
  /*  */
  display: flex;
  flex-direction: column;
  gap: 8px;
  white-space: pre-wrap;
`;

export const ModalTitle = styled.p`
  /*  */
  ${typo.HEADING_4}
  color: ${colors.GRAY2};
`;

const ModalMessage = styled.p`
  /*  */
  ${typo.BODY_6}
  color: ${colors.GRAY6};
`;
