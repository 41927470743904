export default {
  // landing page
  ChargingNonMember: "비회원으로 충전하기",
  OtherServices: "다른 서비스를 이용하시나요?",

  // app
  KakaoNavi: "카카오내비",
  TMap: "T맵",
  EPit: "E-pit",

  // title
  Title: {
    ChargingNonMember: "비회원 결제",
  },

  SubTitle: {
    Precautions: "유의사항",
  },

  // button
  ButtonText: {
    Paying: "결제하기",
    Agree: "동의하기",
    Cancel: "취소하기",
    ChargingEnd: "충전종료",
    PressChargingEndButton: "충전기에서 종료 버튼을 눌러주세요.",
    AppNextTime: "다음에는 앱을 통해 충전할게요.",
    ChargingAsNonMember: "비회원으로 충전하기",
    LowPriceWithEVERON: "에버온으로 저렴하게 충전하기",
    Check: "확인",
    PaymentsTermsAgree: "동의하고 결제하기",
  },

  // modal
  Modal: {
    Title: {
      ConnectionTimeOut: "커넥터 연결 시간이 지났어요.",
      StopCharging: "충전을 중단할까요?",
      StopCharge: "충전을 종료할게요.",
      PressExitButton: "충전기에서 종료 버튼을 눌러주세요.",
      EnteredWrongDate: "잘못 입력했어요.",
      ChargingEndNetworkError: "충전기에서 직접 종료해주세요.",
    },
    Message: {
      ReCertify_Charger: `2분이내 연결이 되지 않아 취소됐어요.\n다시 인증해주세요.`,
      ContinuesChargingFinished: "충전을 종료할 때까지 충전이 계속 진행돼요.",
      StopCharge: "선 결제금액은 3분이내 취소될 예정이에요.",
      EnteredWrongDate:
        "주민번호 앞자리 또는 사업자등록번호를\n정확하게 입력해주세요.",
      ChargingEndNetworkError: "통신이 원활하지 않아 종료되지 않았어요.",
    },

    Button: {
      No: "아니오",
      Yes: "네, 중단할게요.",
    },
  },
};
